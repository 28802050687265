import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import useMenu from 'menu-items/useMenu';
import NavCollapse from './NavCollapse';
import NavItem from './NavItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { d } = useMenu();

    const navItems = d?.map((item) => {
        switch (item.type) {
            case 'group':
                return item.hidden ? null : <NavGroup key={item.id} item={item} />;
            case 'collapse':
                return item.hidden ? null : <NavCollapse key={item.id} menu={item} level={1} />;
            case 'item':
                return item.hidden ? null : <NavItem key={item.id} level={1} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
