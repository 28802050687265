// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconWallet,
    IconHome,
    IconUsers,
    IconFileInvoice,
    IconShieldLock,
    IconArrowsLeftRight
} from '@tabler/icons';
import { SHOW_OPERATOR } from 'constant/auth';
import showMenu from 'constant/role';

// constant
const icons = {
    IconHome,
    IconWallet,
    IconUsers,
    IconFileInvoice,
    IconShieldLock,
    IconArrowsLeftRight,
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const Adminstrator = (role: any) => ({
    hidden: showMenu(role, SHOW_OPERATOR),
    id: 'operator',
    title: <FormattedMessage id="operator" />,
    type: 'group',
    children: [
        {
            id: 'wallet management',
            title: <FormattedMessage id="wallet management" />,
            type: 'collapse',
            icon: icons.IconWallet,
            breadcrumbs: false,
            children: [
                {
                    id: 'wallets',
                    title: <FormattedMessage id="wallets" />,
                    type: 'item',
                    url: '/operator/wallet-management',
                    breadcrumbs: false
                },
                {
                    id: 'Wallet By SubUser',
                    title: <FormattedMessage id="Wallet By SubUser" />,
                    type: 'item',
                    url: '/operator/wallet-management/sub-users',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'transactions management',
            title: <FormattedMessage id="transactions management" />,
            type: 'item',
            url: '/operator/transaction-management',
            icon: icons.IconArrowsLeftRight,
            breadcrumbs: false
        }

        // {
        //     id: 'logs management',
        //     title: <FormattedMessage id="logs management" />,
        //     type: 'item',
        //     url: '/operator/logs-management',
        //     icon: icons.IconFileInvoice,
        //     breadcrumbs: false
        // }
    ]
});

export default Adminstrator;
