export enum KeySearchParamsEnum {
    PAGE_NUMBER = 'pageNumber',
    ID = 'id',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    STATUS = 'status',
    PAYMENT_METHOD = 'method',
    TRANSACTION_ID = 'transactionId',
    TOKEN = 'token',
    ORDER_BY = 'orderBy',
    ORDER_TYPE = 'orderType',
    KEYWORD = 'keyword',
    TYPE = 'type',
    TAB = 'tab'
}
