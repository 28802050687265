import { useTheme, styled } from '@mui/material/styles';
import { INotificationItem } from 'types/notification';
import { Avatar, Divider, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { IconMailbox } from '@tabler/icons';
import moment from 'moment';
import useNotificationApi from 'hooks/useNotificationApi';

interface IItemNotificatoinProps {
    noti: INotificationItem;
}
// styles

const ItemNotification = (props: IItemNotificatoinProps) => {
    const { noti } = props;
    const themeApp = useTheme();
    const { mReadedNotificationById } = useNotificationApi({});
    const ListItemWrapper = styled('div')(({ theme }) => ({
        cursor: 'pointer',
        padding: 16,
        background: noti?.isReaded ? 'unset' : theme.palette.primary.light,
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
        },
        '& .MuiListItem-root': {
            padding: 0
        }
    }));
    const handleReadedNoti = () => {
        !noti?.isReaded && mReadedNotificationById.mutate({ id: noti?.id, isReaded: true });
    };
    return (
        <>
            <ListItemWrapper key={noti?.id} onClick={handleReadedNoti}>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                color: themeApp.palette.primary.dark,
                                backgroundColor:
                                    themeApp.palette.mode === 'dark' ? themeApp.palette.dark.main : themeApp.palette.primary.light,
                                border: themeApp.palette.mode === 'dark' ? '1px solid' : 'none',
                                borderColor: themeApp.palette.primary.main
                            }}
                        >
                            <IconMailbox stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">{noti?.title}</Typography>} />
                    {/* <ListItemSecondaryAction> */}
                    {/* <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {moment.utc(noti?.createdAt).local().startOf('seconds').fromNow()}
                                </Typography>
                            </Grid>
                        </Grid> */}
                    {/* </ListItemSecondaryAction> */}
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{noti?.message}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" display="block" gutterBottom>
                            {moment.utc(noti?.createdAt).local().startOf('seconds').fromNow()}
                        </Typography>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <Divider />
        </>
    );
};

export default ItemNotification;
