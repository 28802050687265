import { ReactNode } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { GenericCardProps } from 'types';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

export interface NavGroupProps {
    item: {
        id?: string;
        type?: string;
        icon?: GenericCardProps['iconPrimary'];
        children?: NavGroupProps['item'][];
        title?: ReactNode | string;
        caption?: ReactNode | string;
        color?: 'primary' | 'secondary' | 'default' | undefined;
        hidden?: boolean;
        url?: string;
    };
    noScroll?: boolean;
}

const NavGroup = ({ item, noScroll = false }: NavGroupProps) => {
    const theme = useTheme();
    // const [items, setItems] = React.useState<JSX.Element[]>();

    // menu list collapse & items

    // React.useEffect(() => {

    const items = item?.children?.map((menu) => {
        // if (menu?.type === 'group') {
        //     return menu?.hidden ? <></> : <NavCollapse key={menu?.id} menu={menu} level={1} />;
        // }
        // return <></>;

        switch (menu?.type) {
            case 'group':
                return menu?.hidden ? <></> : <NavCollapse key={menu?.id} menu={menu} level={1} />;
            case 'collapse':
                return menu?.hidden ? <></> : <NavCollapse key={menu?.id} menu={menu} level={1} />;

            case 'item':
                return noScroll ? (
                    <NavItem noScroll={noScroll} url={menu.url} key={menu.id} item={menu} level={1} length={item?.children?.length} />
                ) : (
                    <NavItem noScroll={noScroll} url={menu.id} key={menu.id} item={menu} level={1} length={item?.children?.length} />
                );
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error111
                    </Typography>
                );
        }
    });
    //     setItems(itemMenus);
    // }, []);

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography
                            sx={{
                                ...theme.typography.menuCaption,
                                color: theme.palette.background.paper,
                                fontWeight: theme.typography.fontWeightBold
                            }}
                            display="block"
                            gutterBottom
                        >
                            {item.title}
                            {item.caption && (
                                <Typography
                                    variant="caption"
                                    sx={{ ...theme.typography.subMenuCaption, color: theme.palette.background.paper }}
                                    display="block"
                                    gutterBottom
                                >
                                    {item.title}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>
        </>
    );
};

export default NavGroup;
