import { useInfiniteQuery, useQuery, useMutation } from 'react-query';
import { IFilterUser } from 'types/user';
import axiosServices from 'utils/axios';
import queryString from 'query-string';
import useAuth from './useAuth';
import { INotificationItem, INotificationReadedByIdData } from 'types/notification';

const useNotificationApi = ({
    keyword = '',
    filter = { page: 0, size: 30 },
    callBack,
    enabledGetNoti = false
}: {
    keyword?: string;
    filter?: IFilterUser;
    callBack?: () => void;
    enabledGetNoti?: boolean;
}) => {
    const { user } = useAuth();
    const merchantId = user?.id;
    const paramsString = queryString.stringify(filter);

    const qGetNotificationList = useInfiniteQuery(
        [`qWebhookLogsList${paramsString}`, paramsString],
        () => axiosServices.get(`/v1/notification/getAll?userId=${merchantId}&${paramsString}`),
        {
            enabled: Boolean(merchantId)
        }
    );

    const qGetTotalNotification = useQuery(['qGetTotalNotication'], () => axiosServices.get(`/v1/notification/getTotal/false`), {
        enabled: enabledGetNoti,
        onSuccess(data: any) {},
        onError(error: any) {}
    });

    const mReadedAllNotification = useMutation(() => axiosServices.post('/v1/notification/readedAll'), {
        onSuccess(data: any) {
            qGetTotalNotification.refetch();
        },
        onError(error: any) {}
    });
    const mReadedNotificationById = useMutation(
        (data: INotificationReadedByIdData) => axiosServices.put(`/v1/notification/${data.id}`, { isReaded: data.isReaded }),
        {
            onSuccess(data: any) {
                qGetNotificationList.refetch();
                qGetTotalNotification.refetch();
            },
            onError(error: any) {}
        }
    );

    return {
        qGetNotificationList,
        dataNotificationList: qGetNotificationList?.data?.pages?.[0]?.data?.content as INotificationItem[],
        qGetTotalNotification,
        dataTotalNotification: qGetTotalNotification?.data?.data as number,
        mReadedAllNotification,
        mReadedNotificationById
    };
};

export default useNotificationApi;
