import { useRoutes, Navigate } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import DocsRoutes from './APIDocs';
import LandingPageRoutes from './LandingPage';
import useRouteWithRole from 'hooks/useRouteWithRole';
import PublicRoutes from './PublicRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const d = useRouteWithRole();
    return useRoutes([
        {
            path: MainRoutes.path,
            element: MainRoutes.element,
            children: d
        },

        DocsRoutes,
        LandingPageRoutes,
        LoginRoutes,
        AuthenticationRoutes,
        PublicRoutes,
        { path: '*', element: <Navigate to="/" /> }
    ]);
}
