import { FormattedMessage } from 'react-intl';
import { IconWallet } from '@tabler/icons';

const menuWalletDocs = () => ({
    id: 'APIs Wallet"',
    title: <FormattedMessage id="APIs Wallet" />,
    type: 'group',
    icon: IconWallet,
    children: [
        {
            id: 'create new wallet address',
            title: <FormattedMessage id="create new wallet address" />,
            type: 'item',
            url: '/signup-docs',
            breadcrumbs: false
        },
        {
            id: 'withdraw-text',
            title: <FormattedMessage id="withdraw-text" />,
            type: 'item',
            url: '/get-api-key-docs',
            breadcrumbs: false
        },
        {
            id: 'get transaction',
            title: <FormattedMessage id="get transaction" />,
            type: 'item',
            url: '/get-api-key-docs',
            breadcrumbs: false
        },
        {
            id: 'get wallet balance',
            title: <FormattedMessage id="get wallet balance" />,
            type: 'item',
            url: '/get-api-key-docs',
            breadcrumbs: false
        }
    ]
});

export default menuWalletDocs;
