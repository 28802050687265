/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { setSession, verifyToken } from 'contexts/JWTContext';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const axiosServices = axios.create({
    baseURL: 'https://wallet-api-internal.xaigate.com/'
});

axiosServices.defaults.headers.common['App-Platform'] = 'WebApp';
// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        dispatch(
            openSnackbar({
                open: true,
                message: (error?.response && error?.response?.data?.message) || 'Wrong Services',
                variant: 'alert',
                alert: {
                    color: 'error',
                    severity: 'error'
                },
                close: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        );
        Promise.reject((error?.response && error?.response?.data) || 'Wrong Services');
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken && !verifyToken(serviceToken)) {
            setSession(null);
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/login';
        }
    }
);

export default axiosServices;
