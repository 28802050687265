import useAuth from 'hooks/useAuth';
import { useMemo } from 'react';
import menuDashboard from './dashboard';
import menuOperator from './operator';
import menuAdminstrator from './adminstrator';
import menuMerchant from './merchant';
import menuWallet from './menu-wallet';

const useMenu = () => {
    const { user } = useAuth();

    const d = useMemo(() => {
        const o = [
            menuDashboard(user?.roleId || 'Merchant'),
            menuWallet(user?.roleId || 'Merchant'),
            menuOperator(user?.roleId || 'Merchant'),
            menuAdminstrator(user?.roleId || 'Merchant'),
            menuMerchant(user?.roleId || 'Merchant')
        ];

        return o;
    }, [user?.roleId]);

    return { d };
};

export default useMenu;
