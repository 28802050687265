import { Box, Divider, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import ButtonSecondary from 'components/Button/ButtonSecondary';
import LogoSection from 'layout/MainLayout/LogoSection';
import { Link } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import { IconBrandFacebook, IconBrandTwitter } from '@tabler/icons';

const Footer = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box sx={{ backgroundColor: '#121212', p: 5 }}>
            <Grid container alignItems="start" spacing={2} justifyContent="center" sx={{ pb: matchDownMd ? 4 : 8 }}>
                <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'start' }}>
                    <Box width="60%">
                        <LogoSection />
                    </Box>
                    <Typography variant={matchDownMd ? 'h6' : 'h5'} sx={{ color: '#DCDCDC', width: '80%' }}>
                        XaiGate Wallet is a cryptocurrency payment gateway, which lets merchants receive crypto payments. Its main intention
                        is to process errorless, fast and secure crypto transactions.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Typography variant={matchDownMd ? 'h6' : 'h5'} sx={{ color: theme.palette.background.paper }}>
                        MENU
                    </Typography>
                    <LinkScroll activeClass="active" to="our-service" spy smooth duration={500} offset={-115}>
                        <Typography
                            sx={{
                                textAlign: 'start',
                                color: '#C8C8C8',
                                cursor: 'pointer'
                            }}
                        >
                            Our Services
                        </Typography>
                    </LinkScroll>
                    <Link to="/docs" style={{ textDecoration: 'none', color: '#C8C8C8' }}>
                        <Typography
                            sx={{
                                textAlign: 'start'
                            }}
                        >
                            APIs Doc
                        </Typography>
                    </Link>
                    <LinkScroll activeClass="active" to="how-does-it-work" spy smooth duration={500} offset={-115}>
                        <Typography
                            sx={{
                                textAlign: 'start',
                                color: '#C8C8C8',
                                cursor: 'pointer'
                            }}
                        >
                            How Does It Work ?
                        </Typography>
                    </LinkScroll>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Typography variant={matchDownMd ? 'h6' : 'h5'} sx={{ color: theme.palette.background.paper }}>
                        SUBSCRIBE NEWSLETTER
                    </Typography>
                    <Box sx={{ backgroundColor: theme.palette.background.paper }} display="flex">
                        <Grid item xs={9}>
                            <TextField
                                variant="outlined"
                                id="filled"
                                placeholder="Ex. John12@example.com"
                                sx={{
                                    py: '0',
                                    width: '100%',
                                    '& fieldset': { borderRadius: 'unset' }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <ButtonSecondary onClick={() => {}} title="submit" sx={{ height: '100%', borderRadius: 'none' }} />
                        </Grid>
                    </Box>
                    <Box display="flex" alignItems="center" gap={3} sx={{ cursor: 'pointer' }}>
                        <IconBrandFacebook />
                        <IconBrandTwitter />
                    </Box>
                </Grid>
            </Grid>
            <Divider variant="middle" />
            <Grid container>
                <Grid item justifyContent="start" sx={{ px: 5, py: 2 }}>
                    <Typography variant={matchDownMd ? 'h6' : 'h5'} sx={{ color: theme.palette.background.paper }}>
                        © 2022 All Rights Reserved. XaiGate Wallet
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Footer;
