import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import Footer from 'views/pages/landing-page/home/footer';
import HeaderLandingPage from './Header';
import Sidebar from './Sidebar';

// ==============================|| MAIN LAYOUT ||============================== //

const LandingPageLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownLg = useMediaQuery('(max-width:820px)');

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar
                sx={{
                    backgroundColor: '#121212',
                    paddingTop: matchDownMd ? '32px' : '46px',
                    paddingBottom: '5px'
                }}
            >
                <Container maxWidth={matchDownLg ? 'lg' : 'xl'}>
                    <HeaderLandingPage />
                </Container>
            </Toolbar>
        ),
        []
    );

    return (
        <Box
            id="ren"
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh', pt: matchDownMd ? '85px' : '0' }}
        >
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: drawerOpen ? theme.transitions.create('width') : 'none'
                }}
            >
                {header}
            </AppBar>
            {/* drawer */}

            {matchDownMd && <Sidebar />}

            {/* main content */}
            <>
                <Outlet />
            </>
            <Footer />
        </Box>
    );
};

export default LandingPageLayout;
