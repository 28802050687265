const color = {
    primary: 'rgb(245 158 11 )',
    white: '#fff',
    grayLight: 'rgb(250, 250, 250)',
    grayDark: '#a1a1a1',
    bgBlack: '#212529',
    red: 'red',
    bgGray: 'rgb(243 243 243)',
    green: 'rgb(14, 203, 129)'
};
export default color;
