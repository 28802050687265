// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Grid, useMediaQuery } from '@mui/material';
// project imports
// import SearchSection from './SearchSection';

// assets
import LocalizationSection from 'layout/LandingPage/Header/LocalizationSection';
import LogoSection from 'layout/MainLayout/LogoSection';
import MenuLandingPage from './menu';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useRedirect, { pages } from 'hooks/useRedirect';
import { IconMenu2 } from '@tabler/icons';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const [selectedIndex] = React.useState<string>(sessionStorage.getItem('route') ?? '');
    // const handleListItemClick = (name: string) => {
    //     sessionStorage.setItem('route', name);
    //     setSelectedIndex(name);
    // };
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'));

    const pagesCenter = useRedirect();

    return (
        <Box sx={{ padding: 0 }}>
            <Grid container sx={{ display: 'flex', px: matchDownLg ? 0 : 4, alignItems: 'center' }}>
                {matchDownMd && (
                    <Grid sx={{ mb: 1, mr: 2 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                overflow: 'hidden',
                                transition: 'all .2s ease-in-out',
                                color: theme.palette.primary.dark,
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.light
                                },
                                width: 26,
                                height: 26
                            }}
                            onClick={() => dispatch(openDrawer(!drawerOpen))}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1rem" />
                        </Avatar>
                    </Grid>
                )}

                <Grid item xs={6} md={3} lg={3.5} sx={{ display: 'flex', justifyContent: 'start' }}>
                    <LogoSection />
                </Grid>
                <Grid
                    container
                    spacing={matchDownMd ? 2 : 5}
                    lg={8.5}
                    md={8}
                    sx={{ display: matchDownMd ? 'none' : 'flex', justifyContent: 'end', alignItem: 'center' }}
                >
                    <Grid item sx={{ display: 'flex' }}>
                        {pagesCenter?.map((page: pages) => (
                            <MenuLandingPage
                                key={page.id}
                                page={page}
                                selectedIndex={selectedIndex}
                                handleListItemClick={() => {}}
                                style={{ minWidth: '70px', padding: '4px 0 !important' }}
                            />
                        ))}
                    </Grid>
                    <Grid item>
                        <Button
                            sx={{
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.background.paper,
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.dark
                                },
                                px: 4,
                                borderRadius: '10px'
                            }}
                        >
                            <FormattedMessage id="sign-up" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <LocalizationSection />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Header;
