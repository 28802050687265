import { FormattedMessage } from 'react-intl';
import { IconWallet } from '@tabler/icons';

const menuGettingStartedDocs = () => ({
    id: 'getting-started',
    title: <FormattedMessage id="getting-started" />,
    type: 'group',
    icon: IconWallet,
    children: [
        {
            id: 'sign up',
            title: <FormattedMessage id="sign up" />,
            type: 'item',
            url: '/register',
            breadcrumbs: false
        },
        {
            id: 'get api key',
            title: <FormattedMessage id="get api key" />,
            type: 'item',
            url: '/get-api-key-docs',
            breadcrumbs: false
        }
    ]
});

export default menuGettingStartedDocs;
