import _ from 'lodash';
import { useMemo } from 'react';

import MainRoutes from 'routes/MainRoutes';
import useAuth from './useAuth';

// ==============================|| AUTH HOOKS ||============================== //

const useRouteWithRole = () => {
    const { user } = useAuth();

    const d = useMemo(() => _.filter(MainRoutes.children, (x) => _.includes(x.roles, user?.roleId || 'Merchant')), [user]);

    return d;
};

export default useRouteWithRole;
