export interface pages {
    id: number;
    name: string;
    route: string;
    scroll: string;
}
const useRedirect = () => {
    const pagesCenter: pages[] = [
        { id: 2, name: 'Services', route: '', scroll: 'our-service' },
        { id: 2, name: 'APIs', route: '/docs', scroll: '' },
        { id: 3, name: 'Log in', route: '/login', scroll: '' }
    ];

    return pagesCenter;
};

export default useRedirect;
