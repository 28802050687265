import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import URL from 'utils/url-page';

// login routing
const PageInvoiceShare = Loadable(lazy(() => import('views/pages/public/invoice-share')));
const PagePayment = Loadable(lazy(() => import('views/pages/public/payment')));
const PageCalculatorFee = Loadable(lazy(() => import('views/pages/public/fees-calculator')));
const PageListYourCoin = Loadable(lazy(() => import('views/pages/public/list-your-coin')));
// ==============================|| AUTH ROUTING ||============================== //

const PublicRoutes = {
    element: <MinimalLayout />,
    children: [
        {
            path: `${URL.public.invoice.share}:invoiceId`,
            element: <PageInvoiceShare />
        },
        {
            path: `${URL.public.payment}/:invoiceNo`,
            element: <PagePayment />
        },
        {
            path: `${URL.public.fee}`,
            element: <PageCalculatorFee />
        },
        {
            path: `${URL.public.listYourCoin}`,
            element: <PageListYourCoin />
        }
    ]
};

export default PublicRoutes;
