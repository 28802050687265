import React, { ReactNode } from 'react';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material';

interface IProps {
    title: string;
    onClick: () => void;
    loading?: boolean;
    disabled?: boolean;
    bgColor?: string;
    icon?: ReactNode;
    autoFocus?: boolean;
    type?: 'button' | 'submit' | 'reset';
    positionIcon?: 'left' | 'right';
    color?: string;
    size?: 'small' | 'medium';
    sx?: any;
}

function ButtonSecondary(props: IProps) {
    const theme = useTheme();
    const {
        title,
        loading = false,
        disabled = false,
        onClick,
        bgColor = theme.palette.primary.dark,
        icon,
        autoFocus = false,
        type = 'button',
        color = theme.palette.background.paper,
        positionIcon = 'right',
        sx,
        size = 'small'
    } = props;

    const cssSize = size === 'small' ? { p: '0.25rem 0.625rem' } : { p: '0.375rem 1rem' };
    return (
        <LoadingButton
            type={type}
            fullWidth
            loading={loading}
            disabled={disabled}
            sx={{
                backgroundColor: bgColor,
                display: 'inline-flex',
                alignItems: 'center',
                gap: 0.5,
                color,
                fontWeight: 600,
                '&:hover': {
                    backgroundColor: bgColor,
                    opacity: 0.9
                },
                '&:disabled': {
                    backgroundColor: theme.palette.grey[500],
                    color: theme.palette.text.hint,
                    cursor: 'not-allowed !important'
                },
                transition: 'all 0.25s',
                minWidth: '4rem',
                ...cssSize,
                ...sx
            }}
            autoFocus={autoFocus}
            onClick={onClick}
        >
            {icon && positionIcon === 'left' && icon}
            <FormattedMessage id={title} />
            {icon && positionIcon === 'right' && icon}
        </LoadingButton>
    );
}

export default ButtonSecondary;
