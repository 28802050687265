import { ForwardRefExoticComponent, RefAttributes, forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, Divider, ListItemButton, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import { useDispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/slices/menu';

// assets

// types
import { LinkTarget, NavItemType } from 'types';

interface NavItemProps {
    item: NavItemType;
    level: number;
    url?: any;
    length?: number;
    noScroll?: boolean;
}

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level, url, length = 0, noScroll = false }: NavItemProps) => {
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { openItem } = useSelector((state) => state.menu);

    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url!} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id: string) => {
        dispatch(activeItem([id]));
        matchesSM && dispatch(openDrawer(false));
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch(activeItem([item.id!]));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {noScroll && url && (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    sx={{
                        alignItems: 'flex-start',
                        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                        py: level > 1 ? 1 : 1.25,
                        pl: `${level * 24}px`,
                        '&.MuiListItemButton-root:hover': {
                            backgroundColor: 'transparent !important'
                        },
                        borderLeft: '1px solid #DCDCDC'
                    }}
                    // selected={openItem?.findIndex((id) => id === item.id) > -1}
                    onClick={() => itemHandler(item.id!)}
                >
                    {/* <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon> */}
                    <Divider sx={{ mt: 0.25, mb: 1.25 }} />
                    <ListItemText
                        sx={{
                            '& h5': {
                                color: `${theme.palette.primary.main} !important`
                            }
                        }}
                        primary={
                            <Typography
                                variant={openItem?.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}
                                // sx={{
                                //     '&.MuiTypography-root ': {
                                //         color: `${theme.palette.primary.main} !important`
                                //     }
                                // }}
                                sx={
                                    {
                                        // '&:active': {
                                        //     color: `${theme.palette.primary.main} !important`
                                        // }
                                    }
                                }
                            >
                                {item.title}
                            </Typography>
                        }
                        secondary={
                            item.caption && (
                                <Typography
                                    variant="caption"
                                    sx={{
                                        ...theme.typography.subMenuCaption,
                                        color: theme.palette.primary.main
                                    }}
                                    display="block"
                                    gutterBottom
                                >
                                    {item.caption}
                                </Typography>
                            )
                        }
                    />
                    {item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            )}

            {noScroll && !url && (
                <LinkScroll activeClass="active" to={item.id!} spy smooth duration={500} offset={-85}>
                    <ListItemButton
                        disabled={item.disabled}
                        sx={{
                            alignItems: 'flex-start',
                            backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                            py: level > 1 ? 1 : 1.25,
                            pl: `${level * 24}px`,
                            '&.MuiListItemButton-root:hover': {
                                backgroundColor: 'transparent !important'
                            },
                            borderLeft: '1px solid #DCDCDC'
                        }}
                        // selected={openItem?.findIndex((id) => id === item.id) > -1}
                        onClick={() => itemHandler(item.id!)}
                    >
                        {/* <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon> */}
                        <Divider sx={{ mt: 0.25, mb: 1.25 }} />
                        <ListItemText
                            sx={{
                                '& h5': {
                                    color: `${theme.palette.primary.main} !important`
                                }
                            }}
                            primary={
                                <Typography
                                    variant={openItem?.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}
                                    // sx={{
                                    //     '&.MuiTypography-root ': {
                                    //         color: `${theme.palette.primary.main} !important`
                                    //     }
                                    // }}
                                    sx={
                                        {
                                            // '&:active': {
                                            //     color: `${theme.palette.primary.main} !important`
                                            // }
                                        }
                                    }
                                >
                                    {item.title}
                                </Typography>
                            }
                            secondary={
                                item.caption && (
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            ...theme.typography.subMenuCaption,
                                            color: theme.palette.primary.main
                                        }}
                                        display="block"
                                        gutterBottom
                                    >
                                        {item.caption}
                                    </Typography>
                                )
                            }
                        />
                        {item.chip && (
                            <Chip
                                color={item.chip.color}
                                variant={item.chip.variant}
                                size={item.chip.size}
                                label={item.chip.label}
                                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                            />
                        )}
                    </ListItemButton>
                </LinkScroll>
            )}
            {!noScroll && (
                <LinkScroll activeClass="active" to={url} spy smooth duration={500} offset={-65}>
                    <ListItemButton
                        disabled={item.disabled}
                        sx={{
                            alignItems: 'flex-start',
                            backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                            py: level > 1 ? 1 : 1.25,
                            pl: `${level * 24}px`,
                            '&.MuiListItemButton-root:hover': {
                                backgroundColor: 'transparent !important'
                            },
                            borderLeft: '1px solid #DCDCDC'
                        }}
                        // selected={openItem?.findIndex((id) => id === item.id) > -1}
                        onClick={() => itemHandler(item.id!)}
                    >
                        {/* <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon> */}
                        <Divider sx={{ mt: 0.25, mb: 1.25 }} />
                        <ListItemText
                            sx={{
                                '& h5': {
                                    color: `${theme.palette.primary.main} !important`
                                }
                            }}
                            primary={
                                <Typography
                                    variant={openItem?.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}
                                    // sx={{
                                    //     '&.MuiTypography-root ': {
                                    //         color: `${theme.palette.primary.main} !important`
                                    //     }
                                    // }}
                                    sx={
                                        {
                                            // '&:active': {
                                            //     color: `${theme.palette.primary.main} !important`
                                            // }
                                        }
                                    }
                                >
                                    {item.title}
                                </Typography>
                            }
                            secondary={
                                item.caption && (
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            ...theme.typography.subMenuCaption,
                                            color: theme.palette.primary.main
                                        }}
                                        display="block"
                                        gutterBottom
                                    >
                                        {item.caption}
                                    </Typography>
                                )
                            }
                        />
                        {item.chip && (
                            <Chip
                                color={item.chip.color}
                                variant={item.chip.variant}
                                size={item.chip.size}
                                label={item.chip.label}
                                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                            />
                        )}
                    </ListItemButton>
                </LinkScroll>
            )}
        </>
    );
};

export default NavItem;
