import _ from 'lodash';
import { ROLE } from './auth';

const showMenu = (role: any, arrayRole: ROLE[]) => {
    const display = _.includes(arrayRole, role);

    return display;
};

export default showMenu;
