// types
import { ConfigProps } from 'types/config';
import firebase from 'firebase/compat/app';

export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const FIREBASE_API = {
    apiKey: 'AIzaSyDNHzwjs8EJXvRaj10nmpd7W8DAzCdmp08',
    authDomain: 'thango-wallet.firebaseapp.com',
    projectId: 'thango-wallet',
    storageBucket: 'thango-wallet.appspot.com',
    messagingSenderId: '606856554665',
    appId: '1:606856554665:web:2172f95ec25eb31291e1b8'
};
firebase.initializeApp(FIREBASE_API);

export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: 'us-east-1_AOfOTXLvD',
    appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

export const RECAPTCHA_GOOGLE = {
    site_key: '6LfLN-MoAAAAAA2fdiNOOCqZ6rb5Ct6AG9rAAynq'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const MERCHANT = '/';
export const OPERATOR = '/operator/wallet-management';
export const ADMIN = '/admin/user-management';
export const DASHBOARD = '/dashboard';
export const DOMAIN = process.env.NODE_ENV === 'production' ? 'https://wallet.xaigate.com' : 'http://localhost:3000';

const config: ConfigProps = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'vie' - Vietnamese, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

// OAuth2 for login by social netwroks as: gooogle, facebook, github
export const API_BASE_URL = 'https://wallet-api-internal.xaigate.com/v1';
export const ACCESS_TOKEN =
    'cjvsseokzqakxawpxncjjgkhrbhbrlaqszaitxmkrynqhkyloncyeveinczwonlslxgdgoiotfuulitejgddmicncvyzwvmrfyibwplcwtcqrwcmbxkisembawowxnei';
export const OAUTH2_REDIRECT_URI = 'https://wallet.xaigate.com/oauth2/redirect';
export const GOOGLE_AUTH_URL = `${API_BASE_URL}/oauth2/authorize/google?redirect_uri=${OAUTH2_REDIRECT_URI}`;
export const FACEBOOK_AUTH_URL = `${API_BASE_URL}/oauth2/authorize/facebook?redirect_uri=${OAUTH2_REDIRECT_URI}`;
export const GITHUB_AUTH_URL = `${API_BASE_URL}/oauth2/authorize/github?redirect_uri=${OAUTH2_REDIRECT_URI}`;

export default config;
