// material-ui
import { List } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// assets
import useNotificationApi from 'hooks/useNotificationApi';
import _ from 'lodash';
import React from 'react';
import { INotificationItem } from 'types/notification';
import ItemNotification from './ItemNotification';

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
    const theme = useTheme();
    const [keyword] = React.useState<string>('');
    const { dataNotificationList } = useNotificationApi({ keyword });

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {_.map(dataNotificationList, (noti: INotificationItem, index: number) => (
                <ItemNotification key={index} noti={noti} />
            ))}
        </List>
    );
};

export default NotificationList;
