import { useMemo } from 'react';
import menuFeatureDocs from './feature';
import menuGettingStartedDocs from './getting-started-docs';
import menuWalletDocs from './wallet-docs';

const useMenuDocs = () => {
    const e = useMemo(() => {
        const i = [menuGettingStartedDocs(), menuWalletDocs(), menuFeatureDocs()];

        return i;
    }, []);

    return { e };
};

export default useMenuDocs;
