import _forEach from 'lodash/forEach';
import _isArray from 'lodash/isArray';
import { useSearchParams } from 'react-router-dom';
import { DataParamFilter, KeySearchParamsType } from 'types/filter';

const useFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSetFilter = (dataParams: DataParamFilter[]) => {
        if (!_isArray(dataParams)) {
            throw new Error('Invalid filter params format');
        }

        const params = new URLSearchParams(searchParams);
        // eslint-disable-next-line consistent-return
        _forEach(dataParams, (param: DataParamFilter) => {
            if (!param.value) {
                return params.delete(param.key);
            }
            params.set(param.key, param.value);
        });
        console.log('testing', params, dataParams);

        setSearchParams({ ...Object.fromEntries(params) });
    };

    const getValueFilter = (keySearchParam: KeySearchParamsType) => {
        return searchParams.get(keySearchParam);
    };

    return { handleSetFilter, getValueFilter, dependenciesEffect: searchParams };
};

export default useFilter;
