import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import GuestGuard from 'utils/route-guard/GuestGuard';
import OAuth2RedirectHandler from 'utils/route-guard/OAuth2RedirectHandler';
import URL from 'utils/url-page';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const AuthVerified = Loadable(lazy(() => import('views/pages/authentication/authentication3/VerifiedCode')));
const AuthChangePassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ChangePassword')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register?ref=:refCode',
            element: <AuthRegister />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },

        {
            path: '/change-password',
            element: <AuthChangePassword />
        },
        {
            path: '/verify-account',
            element: <AuthVerified />
        },
        {
            path: URL.auth.oAuth,
            element: <OAuth2RedirectHandler />
        }
    ]
};

export default LoginRoutes;
