import { lazy } from 'react';

// project imports

import Loadable from 'ui-component/Loadable';
import LandingPageLayout from 'layout/LandingPage';

// login routing
const LandingPage = Loadable(lazy(() => import('views/pages/landing-page/home')));

// ==============================|| AUTH ROUTING ||============================== //

const LandingPageRoutes = {
    path: '/',
    element: <LandingPageLayout />,
    children: [
        {
            path: '/home',
            element: <LandingPage />
        }
    ]
};

export default LandingPageRoutes;
