import { FormattedMessage } from 'react-intl';
import { IconWallet } from '@tabler/icons';

const menuFeatureDocs = () => ({
    id: 'Feature',
    title: <FormattedMessage id="Feature" />,
    type: 'group',
    icon: IconWallet,
    children: [
        {
            id: 'Feature',
            title: <FormattedMessage id="Feature" />,
            type: 'item',
            url: '/signup-docs',
            breadcrumbs: false
        }
    ]
});

export default menuFeatureDocs;
