import React from 'react';
import { Typography, Grid, ListItemButton, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { pages } from 'hooks/useRedirect';
import { Link as LinkScroll } from 'react-scroll';

interface PropsMenu {
    page: pages;

    selectedIndex: string;
    handleListItemClick: any;
    style?: {
        minWidth: string;
        padding?: string;
    };
}
function MenuLandingPage({ page, selectedIndex, handleListItemClick, style }: PropsMenu) {
    const theme = useTheme();

    return (
        <>
            <Grid
                component={ListItemButton}
                item
                // selected={page?.route !== '' && selectedIndex === page.route}
                onClick={handleListItemClick}
                sx={{
                    backgroundColor: 'transparent !important',
                    '&.MuiListItemButton-root:hover': {
                        backgroundColor: 'transparent !important'
                    },
                    '&.Mui-selected': {
                        color: theme.palette.primary.dark
                    }
                }}
            >
                {page.route && (
                    <Link to={page.route} style={{ textDecoration: 'none' }}>
                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: 'center',
                                color: theme.palette.background.paper
                            }}
                        >
                            {page.name}
                        </Typography>
                    </Link>
                )}
                {!page.route && (
                    <LinkScroll activeClass="active" to={page.scroll} spy smooth duration={500} offset={-115}>
                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: 'center',
                                color: theme.palette.background.paper
                            }}
                        >
                            {page.name}
                        </Typography>
                    </LinkScroll>
                )}
            </Grid>
        </>
    );
}

export default MenuLandingPage;
