import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { ROLE } from 'constant/auth';
import URL from 'utils/url-page';

// page merchant
const WalletDetailPage = Loadable(lazy(() => import('views/pages/merchant/wallet/wallet-detail')));
const WalletPage = Loadable(lazy(() => import('views/pages/merchant/wallet')));
const WalletOnlyTokenPage = Loadable(lazy(() => import('views/pages/merchant/wallet-with-only-token')));
const WalletSubUserPage = Loadable(lazy(() => import('views/pages/merchant/wallet-sub-user')));
const TransactionPage = Loadable(lazy(() => import('views/pages/merchant/transaction')));
const CredentialPage = Loadable(lazy(() => import('views/pages/merchant/credential')));
const LogsPage = Loadable(lazy(() => import('views/pages/merchant/logs')));
const ProfileAccountPage = Loadable(lazy(() => import('views/pages/merchant/profile')));
const ConfigPage = Loadable(lazy(() => import('views/pages/merchant/config')));
const PageReferral = Loadable(lazy(() => import('views/pages/merchant/referral')));
const PageWebhook = Loadable(lazy(() => import('views/pages/merchant/webhook')));
const PageInvoices = Loadable(lazy(() => import('views/pages/merchant/invoices')));
const PageCreateInvoices = Loadable(lazy(() => import('views/pages/merchant/invoices/create-invoice')));
const PageInvoiceDetail = Loadable(lazy(() => import('views/pages/merchant/invoices/invoice-detail')));
const FeesCalculatorPage = Loadable(lazy(() => import('views/pages/merchant/fees-calculator')));
const PagePrice = Loadable(lazy(() => import('views/pages/merchant/price')));

// page admin
const PageUsersManagement = Loadable(lazy(() => import('views/pages/administrator/users-management')));
const PageLogsManagement = Loadable(lazy(() => import('views/pages/administrator/logs-management')));
const PageTransactionDetail = Loadable(lazy(() => import('views/pages/administrator/logs-management/components/TransactionDetail')));
// const PageUserManagementOperator = Loadable(lazy(() => import('views/pages/operator/user-management')));

// page operator
const PageTransactionManagementOperator = Loadable(lazy(() => import('views/pages/operator/transactions-management')));
const PageWalletManagement = Loadable(lazy(() => import('views/pages/operator/wallet-management')));
const PageWalletSubUsers = Loadable(lazy(() => import('views/pages/operator/wallet-management/components/wallet-with-only-token')));
const PageWalletBySubUser = Loadable(lazy(() => import('views/pages/operator/wallet-management/components/wallet-sub-user')));

// const PageLogsManagementOperator = Loadable(lazy(() => import('views/pages/operator/logs-management')));

// page crud
const PageCRUDUser = Loadable(lazy(() => import('views/pages/crud/index')));

// page all role
const PageDashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const PageSecurity = Loadable(lazy(() => import('views/pages/merchant/security')));
// const AddNewWallet = Loadable(lazy(() => import('views/pages/wallet/components/AddNewWallet')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // merchant route
        {
            path: '/',
            element: <PageDashboard />,
            roles: [ROLE.Merchant, ROLE.Operator, ROLE.Admin]
        },
        {
            path: '/merchant',
            element: <WalletPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: '/dashboard',
            element: <PageDashboard />,
            roles: [ROLE.Merchant, ROLE.Operator, ROLE.Admin]
        },
        {
            path: '/merchant/wallet',
            element: <WalletPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: '/merchant/wallet/:symbol',
            element: <WalletPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: '/merchant/wallet/sub-user/:userId',
            element: <WalletSubUserPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: '/merchant/wallet/sub-wallet/',
            element: <WalletOnlyTokenPage />,
            roles: [ROLE.Merchant]
        },
        // {
        //     path: '/merchant/wallet/sub-wallet/:symbol',
        //     element: <WalletOnlyTokenPage />,
        //     roles: [ROLE.Merchant]
        // },
        {
            path: '/merchant/wallet-detail/:symbol',
            element: <WalletDetailPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: '/merchant/transaction',
            element: <TransactionPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: '/merchant/sub-user/transaction/:userId',
            element: <TransactionPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: '/merchant/credential',
            element: <CredentialPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: '/merchant/logs',
            element: <LogsPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: '/merchant/config',
            element: <ConfigPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: '/merchant/account-profile',
            element: <ProfileAccountPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: URL.merchant.security,
            element: <PageSecurity />,
            roles: [ROLE.Merchant]
        },
        {
            path: URL.merchant.referral,
            element: <PageReferral />,
            roles: [ROLE.Merchant]
        },
        {
            path: URL.merchant.webhook,
            element: <PageWebhook />,
            roles: [ROLE.Merchant]
        },
        {
            path: URL.merchant.invoices.list,
            element: <PageInvoices />,
            roles: [ROLE.Merchant]
        },
        {
            path: URL.merchant.invoices.add,
            element: <PageCreateInvoices />,
            roles: [ROLE.Merchant]
        },
        {
            path: URL.merchant.fee,
            element: <FeesCalculatorPage />,
            roles: [ROLE.Merchant]
        },
        {
            path: `${URL.merchant.invoices.detail}:invoiceId`,
            element: <PageInvoiceDetail />,
            roles: [ROLE.Merchant]
        },
        {
            path: '/merchant/price',
            element: <PagePrice />,
            roles: [ROLE.Merchant]
        },
        // admin route
        {
            path: '/admin/user-management',
            element: <PageUsersManagement />,
            roles: [ROLE.Admin]
        },

        {
            path: '/admin/logs-management',
            element: <PageLogsManagement />,
            roles: [ROLE.Admin]
        },
        {
            path: '/tx/:id',
            element: <PageTransactionDetail />,
            roles: [ROLE.Admin, ROLE.Operator, ROLE.Merchant]
        },

        {
            path: '/admin/crud',
            element: <PageCRUDUser />,
            roles: [ROLE.Admin]
        },
        // operator route
        {
            path: '/operator/wallet-management',
            element: <PageWalletManagement />,
            roles: [ROLE.Operator, ROLE.Admin]
        },
        {
            path: '/operator/wallet-management/sub-users',
            element: <PageWalletSubUsers />,
            roles: [ROLE.Operator, ROLE.Admin]
        },

        {
            path: '/operator/transaction-management/:merchantId/:userId',
            element: <PageTransactionManagementOperator />,
            roles: [ROLE.Operator, ROLE.Admin]
        },
        {
            path: '/operator/transaction-management/',
            element: <PageTransactionManagementOperator />,
            roles: [ROLE.Operator, ROLE.Admin]
        },
        {
            path: '/operator/wallet-management/merchant/:merchantName/:merchantId/sub-user/:userId',
            element: <PageWalletBySubUser />,
            roles: [ROLE.Operator, ROLE.Admin]
        }

        // {
        //     path: '/operator/logs-management',
        //     element: <PageLogsManagementOperator />,
        //     roles: [ROLE.Operator, ROLE.Admin]
        // }
    ]
};

export default MainRoutes;
