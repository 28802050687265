import { useMemo } from 'react';
import menuLandingPage from './landing-page';

const useMenuLandingPage = () => {
    const f = useMemo(() => {
        const h = [menuLandingPage()];

        return h;
    }, []);

    return { f };
};

export default useMenuLandingPage;
