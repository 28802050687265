// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconWallet,
    IconHome,
    IconUsers,
    IconFileInvoice,
    IconShieldLock,
    IconArrowsLeftRight
} from '@tabler/icons';
import { SHOW_ADMIN } from 'constant/auth';
import showMenu from 'constant/role';

// constant
const icons = {
    IconHome,
    IconWallet,
    IconUsers,
    IconFileInvoice,
    IconShieldLock,
    IconArrowsLeftRight,
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const Adminstrator = (role: any) => ({
    hidden: showMenu(role, SHOW_ADMIN),
    id: 'administrator',
    title: <FormattedMessage id="administrator" />,
    type: 'group',
    children: [
        {
            id: 'user management',
            title: <FormattedMessage id="user management" />,
            type: 'item',
            url: '/admin/user-management',
            icon: icons.IconUsers,
            breadcrumbs: false
        },

        // {
        //     id: 'transactions management',
        //     title: <FormattedMessage id="transactions management" />,
        //     type: 'item',
        //     url: '/admin/transaction-management',
        //     icon: icons.IconArrowsLeftRight,
        //     breadcrumbs: false
        // },

        {
            id: 'logs management',
            title: <FormattedMessage id="logs management" />,
            type: 'item',
            url: '/admin/logs-management',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        }
        // {
        //     id: 'Template CRUD',
        //     title: <FormattedMessage id="Template CRUD" />,
        //     type: 'item',
        //     url: '/admin/crud',
        //     icon: icons.IconFileInvoice,
        //     breadcrumbs: false
        // }
    ]
});

export default Adminstrator;
