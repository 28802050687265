import { FormattedMessage } from 'react-intl';
import { IconHome } from '@tabler/icons';
import { ALL_ROLE } from 'constant/auth';
import showMenu from 'constant/role';

const menuDashboard = (role: string) => ({
    hidden: showMenu(role, ALL_ROLE),
    id: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: IconHome
        }
    ]
});

export default menuDashboard;
