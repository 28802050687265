import { SHOW_MERCHANT } from 'constant/auth';
import showMenu from 'constant/role';
import { FormattedMessage } from 'react-intl';
import { IconWallet } from '@tabler/icons';

const menuWallet = (role: any) => ({
    hidden: showMenu(role, SHOW_MERCHANT),
    id: 'wallets',
    title: <FormattedMessage id="wallets" />,
    type: 'group',
    // type: 'collapse',
    // icon: IconWallet,
    children: [
        {
            id: 'Merchant wallets',
            title: <FormattedMessage id="merchant-wallets" />,
            type: 'item',
            url: '/merchant/wallet',
            breadcrumbs: false,
            icon: IconWallet
        },
        {
            id: 'Client wallets',
            title: <FormattedMessage id="client-wallets" />,
            type: 'item',
            url: '/merchant/wallet/sub-wallet',
            breadcrumbs: false,
            icon: IconWallet
        }
    ]
});

export default menuWallet;
