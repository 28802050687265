export enum ROLE {
    Admin = 'Admin',
    Operator = 'Operator',
    Merchant = 'Merchant'
}
export const SHOW_ADMIN = [ROLE.Merchant, ROLE.Operator];
export const SHOW_OPERATOR = [ROLE.Merchant];
export const SHOW_MERCHANT = [ROLE.Operator, ROLE.Admin];
export const ALL_ROLE = [];
export const SERVICE_TOKEN = 'serviceToken';
export default ROLE;
