// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';
export const CHANGE_NETWORK = 'CHANGE_NETWORK';
export const MOUNT = 'MOUNT';

// action - payment reducer
export const PAYMENT_ACTION = {
    CHANGE_STEP: 'CHANGE_STEP',
    SET_COMPLETED: 'SET_COMPLETED'
};
