import { lazy } from 'react';

// project imports

import Loadable from 'ui-component/Loadable';
import APIDocsLayout from 'layout/APIDocLayout';

// login routing
const DocsPage = Loadable(lazy(() => import('views/pages/docs')));

// ==============================|| AUTH ROUTING ||============================== //

const APIDocsRoutes = {
    element: <APIDocsLayout />,
    children: [
        {
            path: '/docs',
            element: <DocsPage />
        }
    ]
};

export default APIDocsRoutes;
