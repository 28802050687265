import { FormattedMessage } from 'react-intl';
import { IconWallet } from '@tabler/icons';

const menuLandingPage = () => ({
    id: 'wallets',
    type: 'group',
    icon: IconWallet,
    children: [
        {
            id: 'our-service',
            title: <FormattedMessage id="our-service" />,
            type: 'item',
            url: '',
            breadcrumbs: false
        },
        {
            id: 'apis',
            title: <FormattedMessage id="apis" />,
            type: 'item',
            url: '/docs',
            breadcrumbs: false
        },
        {
            id: 'log in',
            title: <FormattedMessage id="login" />,
            type: 'item',
            url: '/docs',
            breadcrumbs: false
        }
    ]
});

export default menuLandingPage;
